.leverage-container {
    background-color: black;
}

.leverage-inner-container {
    display: flex;
    flex-direction: column;
}

.leverage-text {
    font-size: 40px;
    text-align: center;
    font-weight: 600;
    color: white;
    max-width: 800px;
    margin: 10px auto 0 auto;
    display: flex;
}

@media screen and (max-width: 989px) {
    .leverage-text {
        font-size: 20px;
        /*display: block;*/
    }
}

.fade-out-0 {
    opacity: 0;
    animation: fadeOut 1s;
    -webkit-animation-fill-mode: forwards;
}

.fade-out-1 {
    opacity: 0;
    animation: fadeOut 1s;
    -webkit-animation-fill-mode: forwards;
    animation-delay: 0.25s;
}

.fade-out-2 {
    opacity: 0;
    animation: fadeOut 1s;
    -webkit-animation-fill-mode: forwards;
    animation-delay: 0.5s;
}

.fade-out-3 {
    opacity: 0;
    animation: turn 2.75s;
    -webkit-animation-fill-mode: forwards;
    animation-delay: 0.75s;
}

.fade-out-4 {
    opacity: 0;
    animation: turn 2.75s;
    -webkit-animation-fill-mode: forwards;
    animation-delay: 1s;
}

.fade-out-5 {
    opacity: 0;
    animation: fadeOut 1s;
    -webkit-animation-fill-mode: forwards;
    animation-delay: 1.25s;
}

.fade-out-6 {
    opacity: 0;
    animation: fadeOut 1s;
    -webkit-animation-fill-mode: forwards;
    animation-delay: 1.5s;
}

.fade-out-7 {
    opacity: 0;
    animation: fadeOut 1s;
    -webkit-animation-fill-mode: forwards;
    animation-delay: 1.75s;
}

.fade-out-8 {
    opacity: 0;
    animation: fadeOut 1s;
    -webkit-animation-fill-mode: forwards;
    animation-delay: 2s;
}

.fade-out-9 {
    opacity: 0;
    animation: fadeOut 1s;
    -webkit-animation-fill-mode: forwards;
    animation-delay: 2.25s;
}

.fade-out-10 {
    opacity: 0;
    animation: fadeOut 1s;
    -webkit-animation-fill-mode: forwards;
    animation-delay: 2.5s;
}

.square {
    opacity: 0;
}

@keyframes turn {
    0% {
        color: white;
        opacity: 0;
    }
    25% {
        color: white;
        opacity: 1;
    }
    75% {
        color: white;
        opacity: 1;
    }
    100% {
        color: #FF8A00;
        opacity: 1;
    }
}


@keyframes fadeOut {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}