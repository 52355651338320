.footer-bottom {
    display: flex;
    margin: 20px 0;
}

.footer-bottom-mention {
    margin: auto auto auto 0;
}

.footer-bottom-text {
    margin: auto 0 auto 10px;
}

@media screen and (max-width: 768px) {
    .footer-bottom-mention {
        font-size: 16px;
    }

    .footer-bottom-text {
        font-size: 16px;
        margin: 10px 10px 10px 0;
    }

    .footer-bottom {
        display: block;
    }
}


.footer-logo {
    width: 120px;
}

.footer-newsletter-text {
    font-weight: 600;
    font-size: 16px;
}

.footer-desc-text {
    font-weight: 400;
    font-size: 14px;
    margin-top: 10px;
    color: #4D5761;
}

.footer-info-container {
    display: flex;
    height: 100%;
}

.footer-info-text {
    color: #6B7280;
    font-size: 18px;
}

.footer-info-inner-container {
    margin: auto;
}

.footer-desc-container {
    margin-bottom: 30px;
}

.footer-subscribe-btn {
    background-color: #FF8A00;
    color: white;
    font-size: 16px;
    font-weight: 600;
    width: 130px;
    margin: 20px auto 0 auto;
    padding: 12px;
}

@media screen and (max-width: 768px) {
    .footer-newsletter-text {
        font-size: 16px;
    }

    .footer-info-text {
        font-size: 16px;
    }

    .footer-info-inner-container {
        margin: auto 0;
    }

    .footer-desc-container {
        margin-bottom: 0;
    }

    .footer-subscribe-btn {
        font-size: 14px;
        padding: 6px 16px;
        width: 100px;
        font-weight: 500;
        margin: 20px auto 10px auto;
    }

    .footer-desc-text {
        font-size: 12px;
        line-height: 1rem;
    }
}
