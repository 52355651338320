.hero {
    background-color: #F7F7F7;
    height: 100vh;
    display: flex;
    position: relative;
    flex-direction: column;
    z-index: 0;
}

.nova {
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    overflow: hidden;
    position: absolute;
    z-index: -10;
    pointer-events: none;
}

.navbar {
    width: 100%;
    height: 100px;
    display: flex;
    z-index: 10;
}

.navbar-logo {
    margin: auto auto auto 0;
    width: 120px;
}

.our-services-btn {
    margin: auto 10px;
    font-size: 16px;
    padding: 12px 32px;
}

.navbar-newsletter-btn {
    background-color: #FF8A00;
    color: white;
    margin: auto 0;
    font-size: 16px;
    padding: 12px 32px;
}

@media screen and (max-width: 768px) {
    .hero {
        height: 100vh;
    }

    .navbar-logo {
        width: 90px;
        margin: auto 0;
    }

    .our-services-btn {
        margin: auto 0;
        font-size: 14px;
        font-weight: 600;
        padding: 6px 8px;
    }

    .navbar-newsletter-btn {
        font-size: 14px;
        padding: 6px 12px;
        font-weight: 600;
    }
}

.hero-body {
    margin: auto 0;
    z-index: 0;
    background-color: transparent !important;
}

.hero-body-heading {
    font-size: 72px;
    font-weight: 700;
    line-height: 1;
}

.hero-body-meta {
    font-weight: 400;
    font-size: 18px;
    margin: 24px 0 0;
    line-height: 1;
    max-width: 368px;
}

@media screen and (max-width: 768px) {
    .hero-body-heading {
        font-size: 48px;
    }

    .hero-body-meta {
        font-size: 16px;
        font-weight: 600;
        max-width: 287px;
    }
}

.hero-btn-div {
    display: flex;
}

.hero-body-btn {
    font-size: 16px;
    font-weight: 600;
    margin: auto 20px auto 0;
    padding: 12px 32px;
}

.lets-talk-btn {
    background-color: #FF8A00;
    color: white;
}

.our-services-body-btn {
    background-color: white;
    border: 1px solid #1A1A1A;
}

@media screen and (max-width: 768px) {
    .hero-body-btn {
        font-size: 14px;
        padding: 5px 8px;
    }
}

@keyframes zoomOut {
    from {
        opacity: 0;
        transform: scale(0.2);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

.zoomOut {
    opacity: 0;
    animation: zoomOut 0.5s;
    animation-fill-mode: forwards;
    animation-delay: 0.25s;
}
