
.about-us-container {
    display: flex;
    flex-direction: column;
}

.about-us-heading {
    line-height: 2rem;
    font-size: 1.8rem;
    font-weight: 600;
    color: #FF8A00;
    margin: 0 auto 20px auto;
}

.about-us-text {
    line-height: 35px;
    font-size: 1.25rem;
    text-align: center;
    margin: 40px auto;
    max-width: 800px;
    color: #5e5f60;
}

@media screen and (max-width: 768px) {
    .about-us-heading {
        font-size: 18px;
        margin-bottom: 1.5rem;
    }

    .about-us-text {
        line-height: 24px;
        font-size: 16px;
        margin: 10px auto;
    }
}
