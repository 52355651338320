.team-container {
    text-align: center;
}

.team-member-container {
    width: 100%;
    display: flex;
    padding-left: 3rem;
    padding-right: 3rem;
    background-color: #FF8A00;
    border-radius: 30px;
}

.team-member-photo {
    width: 100%;
    margin: auto auto 0 auto;
}

.team-member-name {
    font-size: 32px;
    font-weight: 700;
    text-align: left;
    margin-top: 15px;
}

.team-member-role {
    font-weight: 400;
    color: #FF8A00;
    text-align: left;
}


@media screen and (max-width: 768px) {
    .team-member-name {
        text-align: center;
    }

    .team-member-role {
        font-size: 18px;
        text-align: center;
        margin-bottom: 60px;
    }
}
