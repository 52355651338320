.subscribe-container {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
}

.subscribe-inner-container {
    text-align: center;
    margin: auto;
}

.subscribe-img {
    width: 100px;
    border-radius: 5px;
    margin-bottom: 20px;
}

.subscribe-heading {
    font-size: 48px;
    font-weight: 400;
    line-height: 1;
}

.subscribe-text {
    font-size: 18px;
    font-weight: 400;
    width: 650px;
    margin-top: 12px;
    color: #222222;
    opacity: 75%;
}

.enter-email-container {
    border-radius: 10px;
    border: 2px solid black;
    display: flex;
    max-width: 450px;
    margin: 20px auto 0 auto;
}

.enter-email-subscribe-btn {
    background-color: black;
    color: white;
    padding: 15px;
    font-size: 18px;
    font-weight: 600;
}

@media screen and (max-width: 768px) {
    .subscribe-heading {
        font-size: 24px;
    }

    .subscribe-text {
        width: 340px;
    }
}
