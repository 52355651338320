.get-in-touch-container {
    text-align: center;
    display: flex;
    flex-direction: column;
}

.get-in-touch-fields-container {
    max-width: 800px;
    margin: auto;
}

@media screen and (max-width: 567px) {
    .get-in-touch-fields-container {
        max-width: 300px;
    }
}

.get-in-touch-input {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #9CA3AF;
    border-radius: 0;
    color: #9CA3AF;
}

.get-in-touch-input:focus {
    color: #9CA3AF;
    box-shadow: none;
    border-bottom: 1px solid #9CA3AF;
}

.get-in-touch-input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #9CA3AF;
}

.get-in-touch-select {
    background-color: #EFEFEF;
}

.let-get-in-touch-btn {
    background-color: #FF8A00;
    color: white;
    font-size: 16px;
    font-weight: 600;
    width: 130px;
    margin: 20px auto 0 auto;
    padding: 12px;
}

@media screen and (max-width: 768px) {
    .let-get-in-touch-btn {
        font-size: 14px;
        padding: 6px 16px;
        width: 70px;
        font-weight: 500;
    }
}
