.services-inner-container {
    text-align: center;
}

.services-heading {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
    color: #FF8A00;
}

.veba-container {
    display: flex;
    width: 100%;
}

.service-canvas {
    z-index: 10;
    display: flex;
    width: 100%;
}

.service-canvas > * {
    margin: auto;
}

.services-sm {
    display: none;
}

.swiper-pagination {
    top: 190px;
}

.service-container-outer {
    background-color: white;
    height: 240px;
}

.swiper-pagination-bullet-active {
    background-color: #e1e1e1 !important;
}

@media screen and (max-width: 768px) {
    .service-container {
        height: 200px;
        border: 1px solid #e1e1e1;
    }

    .services-lg {
        display: none;
    }

    .services-sm {
        display: block;
    }
}

@media screen and (max-width: 500px) {
    .service-canvas > * {
        width: 350px !important;
    }
}

.service-container {
    padding: 20px 30px;
    border-radius: 20px;
}

.service-name-container {
    text-align: center;
    margin-bottom: 16px;
}

.service-name-container > p {
    font-size: 22px;
    font-weight: 700;
    line-height: 1;
}

.service-service-container > p {
    text-align: center;
    color: #4D5761;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
}


.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
